export const checkWalletStatus = () => {
    if (typeof window.ethereum != 'undefined') {
        return window.ethereum;
    }

    return false;
};

export const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener, noreferrer')
    if (newWindow) newWindow.opener = null
}

export const shorten = (string, max_length = 20) => {
    if (string.length > max_length) {
        return string.substr(0, 6) + "..." + string.substr(string.length - 4, string.length);
    }
    return string;
}
