import React from 'react';
import Button from '../Button/Button';
import * as Utils from '../../shared/Utils';
import './Header.scss';

class Header extends React.Component {

    constructor(props) {
        super(props);

        if (typeof(window.ethereum) != 'undefined') {
            this.state = {
                wallet: window.ethereum,
                selectedChain: window.ethereum.chainId,
                selectedAccount: null,
            };
        }
        else {
            this.state = {
                wallet: null,
                selectedAccount: null,
                selectedChain: null
            };
        }

        this.connectWallet = this.connectWallet.bind(this);
    }

    componentDidMount() {
        if (this.state.wallet) {
            this.state.wallet.request({ method: 'eth_accounts' }).then((accounts) => {
                this.setState({
                    selectedAccount: accounts[0],
                    selectedChain: window.ethereum.chainId
                });

                this.state.wallet.on('accountsChanged', (accounts) => {
                    //this.setState({ selectedAccount: accounts[0], selectedChain: window.ethereum.chainId });
                    window.location.reload();
                });

                this.state.wallet.on('chainChanged', (chainId) => {
                    //this.setState({ selectedChain: chainId });
                    window.location.reload();
                });

            });
        }

    }

    async connectWallet() {
        await this.state.wallet.enable();
        this.state.wallet.request({ method: 'eth_requestAccounts' }).then((accounts) => {
            this.setState({ selectedAccount: accounts[0], selectedChain: window.ethereum.chainId });
        });
    }

    render() {
        let WalletButton;

        if (this.state.wallet && this.state.wallet.isMetaMask) {
            if (this.state.wallet.selectedAddress) {
                if (parseInt(this.state.selectedChain) === parseInt(process.env.REACT_APP_CHAIN_ID)) {
                    WalletButton = (
                        <Button label={ 'Connected with ' + Utils.shorten(this.state.wallet.selectedAddress) } />
                    )
                }
                else {
                    WalletButton = (
                        <Button label={ 'Connected to wrong chain' } />
                    )
                }
            }
            else {
                WalletButton = (
                    <Button label={ 'Connect wallet' } onClick={ this.connectWallet } />
                )
            }
        }
        else {
            WalletButton = (
                <Button label={ 'Please install MetaMask' } href="https://metamask.io/download/" />
            )
        }

        return (
            <header className="header main-header" role="banner">
                <div className="topbar dark">
                    <div className="uk-container">
                        <div className="uk-grid-medium"  data-uk-grid>
                            <div className="uk-width-1-2@m">
                                <div className="small-top-lines dark"></div>
                                <div className="uk-float-left">
                                    <div className="logo-wrapper">
                                        <a href="/">
                                            <img className="logo" src={"./img/voltz-logo-black.png"} alt="VOLTZ Logo" />
                                        </a>
                                    </div>
                                </div>
                                <div className="uk-float-right">
                                    <ul className="social">
                                        <li>
                                            <a href="https://twitter.com/voltz_me" target="_blank" rel="noreferrer">
                                                <img src={"./img/twitter.png"} alt="VOLTZ Twitter" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://discord.gg/jtWtpKWRzw" target="_blank" rel="noreferrer">
                                                <img src={"./img/discord.png"} alt="VOLTZ Discord" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://medium.com/@voltz_me/voltz-avatars-d0cfc8802e10" target="_blank" rel="noreferrer">
                                                <img src={"./img/medium.png"} alt="VOLTZ Medium" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="https://opensea.io/collection/voltz-avatar-mint-vial" target="_blank" rel="noreferrer">
                                                <img src={"./img/opensea.png"} alt="VOLTZ Opensea" />
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <div className="header-right">
                                    <div className="small-top-lines dark uk-visible@m"></div>
                                    { process.env.REACT_APP_STATUS !== "0" ? WalletButton : null }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        );
    }

}

export default Header;
