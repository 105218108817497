import React from "react";
import './Spinner.scss';

class Spinner extends React.Component {

    render() {
        return (
            <div className="spinner">
                <div data-uk-spinner></div>
            </div>
        )

    }
}

export default Spinner;