import React from 'react';
import Button from './../../components/Button/Button';
import Api from '../../api/Api';
import parse from 'html-react-parser';

import './MigrateButton.scss';

class Migrate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            metamask: window.ethereum,
            apiMigrateResponse: null,
            migrate_in_progress: false,
            migrated: false
        };

        this.migrateVial = this.migrateVial.bind(this);
    }

    componentDidMount() {
        if (typeof(window.ethereum) != 'undefined') {
            window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
                this.setState({
                    metamask: window.ethereum,
                    selectedAccount: accounts[0],
                    selectedChain: window.ethereum.chainId
                });
            });
        }
    };

    migrateVial(token_id) {

        let self = this;

        if (token_id !== 0) {

            self.setState({
                migrate_in_progress: true,
                apiMintResponse: false
            });

            Api.migrate(token_id).then(async (json) => {
                if (json.status === 'error') {
                    self.setState({
                        apiMintResponse: json.message
                    });
                }
                else {
                    const value = 0;
                    const gas = 300000;
                    const transactionParameters = {
                        gas: '0x' + gas.toString(16),
                        to: json.data.contract,
                        from: this.state.selectedAccount,
                        value: '0x' + value.toString(16),
                        data: json.data.tx_data
                    };

                    const txHash = await this.state.metamask.request({
                        method: 'eth_sendTransaction',
                        params: [ transactionParameters ],
                    });

                    if (window.ethereum.chainId === '0x4') {
                        self.setState({
                            apiMigrateResponse: '<p className="response-text">' + this.props.refreshLabel + '</p><a className="response-text" href="https://rinkeby.etherscan.io/tx/' + txHash + '" target="_blank"  rel="noreferrer">https://rinkeby.etherscan.io/tx/' + txHash + '</a><br/><br/><div><a href="https://opensea.io/' + this.state.selectedAccount + '?search[sortBy]=LISTING_DATE&search[query]=voltz" target="_blank" rel="noreferrer"><div className="avatar-icon"></div></a><a href="https://opensea.io/' + this.state.selectedAccount + '?search[sortBy]=LISTING_DATE&search[query]=voltz" target="_blank"  rel="noreferrer"><div className="avatar-icon"></div></a><a href="https://opensea.io/' + this.state.selectedAccount + '?search[sortBy]=LISTING_DATE&search[query]=voltz" target="_blank"  rel="noreferrer"><div className="avatar-icon"></div></a></div>',
                            migrated: true
                        });
                    }
                    else {
                        self.setState({
                            apiMigrateResponse: '<p className="response-text">' + this.props.refreshLabel + '</p><a className="response-text" href="https://etherscan.io/tx/' + txHash + '" target="_blank" rel="noreferrer">https://etherscan.io/tx/' + txHash + '</a><br/><br/><div><a href="https://opensea.io/' + this.state.selectedAccount + '?search[sortBy]=LISTING_DATE&search[query]=voltz" target="_blank" rel="noreferrer"><div className="avatar-icon"></div></a><a href="https://opensea.io/' + this.state.selectedAccount + '?search[sortBy]=LISTING_DATE&search[query]=voltz" target="_blank" rel="noreferrer"><div className="avatar-icon"></div></a><a href="https://opensea.io/' + this.state.selectedAccount + '?search[sortBy]=LISTING_DATE&search[query]=voltz" target="_blank" rel="noreferrer"><div className="avatar-icon"></div></a></div>',
                            migrated: true
                        });
                    }
                }

                self.setState({
                    migrate_in_progress: false
                });

            }).catch(function(e) {
                self.setState({
                    apiMigrateResponse: e.message,
                    migrate_in_progress: false
                });

                console.log(e); // "oh, no!"
            });
        }
    };

    render() {

        let migrate_button, spinner;

        if (parseInt(this.state.selectedChain) === parseInt(process.env.REACT_APP_CHAIN_ID)) {
            migrate_button = (
                <div className="actions">
                    <Button label={"Reveal Vial #" + this.props.token_id} onClick={() => this.migrateVial(this.props.token_id)} />
                </div>
            );
        }
        else {
            migrate_button = (
                <div className="list-sold-out">Connected to wrong chain</div>
            );
        }

        spinner = (
            <div className="actions">
                <div data-uk-spinner></div>
            </div>
        );

        return (
            <div>
                { this.state.migrate_in_progress ? spinner :  (this.state.migrated ? null : migrate_button) }
                <div className="tx-output">{ this.state.apiMigrateResponse ? parse(this.state.apiMigrateResponse) : null }</div>
            </div>
        );

    }

}

export default Migrate;
