import React from 'react';
import './Footer.scss';

class Footer extends React.Component {

    render() {
        return (
            <footer>
                <div className="topbar dark">
                    <div className="uk-container">
                        <div className="uk-grid-medium uk-flex uk-flex-bottom"  data-uk-grid>
                            <div className="uk-width-1-2@m">
                                <div className="footer-left">
                                    <p><a href="https://voltz.me" rel="noreferrer">voltz.me</a><br/>Design, 3D Modeling, Software Development: <a href="https://baako.com" target="_blank" rel="noreferrer">BAAKO</a></p>
                                </div>
                                <div className="small-top-lines dark"></div>
                            </div>
                            <div className="uk-width-1-2@m">
                                <div className="footer-right">
                                    <img src={"./img/footerDX.png"} alt="VOLTZ footer" />
                                </div>
                                <div className="small-top-lines dark"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
}

export default Footer;
