import { render } from "react-dom";

import '../node_modules/uikit/dist/js/uikit.min.js';
import '../node_modules/uikit/dist/css/uikit.min.css';

import './index.scss';

import Header from './components/Header/Header'
import Main from "./pages/Main";
import Footer from './components/Footer/Footer'

const rootElement = document.getElementById("root");

render(
    <>
        <Header />
        <Main />
        <Footer />
    </>
, rootElement);