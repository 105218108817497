import React from 'react';
import Button from '../components/Button/Button';
import MigrateButton from '../components/MigrateButton/MigrateButton';
import Countdown from 'react-countdown';
import Spinner from '../components/Spinner/Spinner';
import Api from '../api/Api';

import parse from 'html-react-parser';
import {isMobile} from 'react-device-detect';

import './Migrate.scss';

class Migrate extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            metamask: window.ethereum,
            selectedAccount: null,
            selectedAccountLinkToOpensea: null,
            currentStage: null,
            isSoldout: null,
            collectionTitle: null,
            stagesAttributes: null,
            spinner: false,
            labels: [],
            totalVials: 0,
            vialsGrid: 'uk-width-1-4@m uk-width-1-2@s',
            vials: []
        };

        this.loadCollectionAvatar = this.loadCollectionAvatar.bind(this);
    }

    loadCollectionAvatar() {
        this.setState({ spinner: true });

        Api.collectionAvatar().then((json) => {
            if (json.status === 'success') {

                //console.log(json.data);

                this.setState({
                    collectionTitle: json.data.title,
                    stagesAttributes: json.data.stages_attributes,
                    currentStage: json.data.stage,
                    isSoldout: json.data.is_soldout,
                    labels: json.data.labels
                });

                if (process.env.REACT_APP_STATUS !== "0") {
                    if (!this.state.isSoldout && this.state.selectedAccount) {
                        Api.checkVial(this.state.selectedAccount).then((json) => {
                            if (json.data.total) {
                                if (json.data.total <= 4) {
                                    this.setState({
                                        vialsGrid: 'uk-width-1-4@m uk-width-1-2@s'
                                    });
                                } else {
                                    this.setState({
                                        vialsGrid: 'uk-width-1-4@m uk-width-1-2@s'
                                    });
                                }
                                this.setState({
                                    vials: json.data.result,
                                    totalVials: json.data.total,
                                    spinner: false
                                });
                            } else {
                                this.setState({ spinner: false });
                            }
                        });
                    } else {
                        this.setState({ spinner: false });
                    }
                } else {
                    this.setState({ spinner: false });
                }

            }
            else if (json.status === 'error') {
                // manage API errors
            }
        });
    }

    componentDidMount() {
        if (typeof(window.ethereum) != 'undefined') {
            window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
                this.setState({
                    metamask: window.ethereum,
                    selectedAccount: accounts[0],
                    selectedAccountLinkToOpensea: "https://opensea.io/" + accounts[0] + "?search[sortBy]=LISTING_DATE&search[query]=voltz"
                });

                this.loadCollectionAvatar();
            });
        } else {
            this.loadCollectionAvatar();
        }
    };

    btnClick() {
        if (this.state.selectedAccount) {
            window.open("https://opensea.io/" + this.state.selectedAccount + "?search[resultModel]=ASSETS&search[sortBy]=LAST_TRANSFER_DATE&search[query]=VOLTZ");
        } else {
            window.open("https://opensea.io/account?search[resultModel]=ASSETS&search[sortBy]=LAST_TRANSFER_DATE&search[query]=VOLTZ");
        }
    }

    render() {

        let content;

        const CountdownCompleted = () => <span>{ this.state.labels._VOLTZ_MIGRATE_IS_OPEN_TITLE ? this.state.labels._VOLTZ_MIGRATE_IS_OPEN_TITLE : null }</span>;

        const renderer = ({ days, hours, minutes, seconds, completed }) => {
            if (completed) {
                // Render a completed state
                return <CountdownCompleted />;
            }
            else {
                // Render a countdown
                return <span className="countdown"><strong>{days}</strong>d:<strong>{hours}</strong>h:<strong>{minutes>9?minutes:'0'+minutes}</strong>m:<strong>{seconds>9?seconds:'0'+seconds}</strong>s</span>;
            }
        };

        if (this.state.isSoldout) {
            content = (
                <div className="uk-container uk-margin-large-bottom">
                    <div className="top-title">{ this.state.labels._VOLTZ_MIGRATE_COMPLETED }</div>
                    <div className="top-subtitle">{ this.state.labels._VOLTZ_MIGRATE_COMPLETED_MESSAGE }</div>
                </div>
            );
        }
        else {

            let activation;

            activation = false;
            if (String(this.state.selectedAccount).toUpperCase() === '0x4377BEEF614c12dCc814bE4cAa7Cd732a2a48d89'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0xF689FCe02340191472d5723A9381050d52995856'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0x2843afe4a48277658c7841e7c90325700f42ed79'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0x1BF341B75A5a346B1A91895C4EC50352cB00108B'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0xB8B7DC5b439f72154DafC2c13376D861E0C19513'.toUpperCase() ||
                String(this.state.selectedAccount).toUpperCase() === '0xD347d913f6C500F18E75BB0F5192899faf4853c2'.toUpperCase() ) {
                activation = true;
            }

            content = (
                <div>
                    {
                    this.state.currentStage === 0 ?
                        <div className="uk-container uk-margin-large-bottom">
                            <div className="top-title"><Countdown date={this.state.stagesAttributes ? null : null} renderer={renderer} /></div>
                            <div className="top-subtitle">{ parse(this.state.labels._VOLTZ_MIGRATE_COUNTDOWN_TO_OPEN_MESSAGE) }</div>
                            { this.state.selectedAccount ? <div className="eth-address-wrapper"><div className="eth-address">{ this.state.selectedAccount }</div></div> : null }
                        </div> :
                        <div className="uk-container uk-margin-large-bottom">
                            <div className="top-title">{ this.state.labels._VOLTZ_MIGRATE_IS_OPEN }</div>
                            { this.state.selectedAccount && this.state.totalVials ?
                                <div className="top-subtitle">{ isMobile ? this.state.labels._VOLTZ_MIGRATE_MOBILE_WARNING : <div className="uk-margin-medium-bottom">{ this.state.labels._VOLTZ_MIGRATE_IS_OPEN_SUBTITLE ? parse(this.state.labels._VOLTZ_MIGRATE_IS_OPEN_SUBTITLE) : null }</div> }</div> :
                                (this.state.labels._VOLTZ_MIGRATE_IS_OPEN_CONNECT_WALLET_TO_MINT ? (this.state.selectedAccount || process.env.REACT_APP_STATUS === "0" ? null : <div className="eth-address-wrapper"><div className="eth-address">{ this.state.labels._VOLTZ_MIGRATE_IS_OPEN_CONNECT_WALLET_TO_MINT }</div></div>) : null)
                            }
                            { this.state.selectedAccount && this.state.totalVials ? <div className="eth-address-wrapper"><div className="eth-address">{ this.state.selectedAccount }</div></div> : null }
                        </div>
                    }
                    { process.env.REACT_APP_STATUS !== "0" && this.state.selectedAccount ?
                        (this.state.totalVials ?
                            <div>
                                <div className="uk-container uk-margin-large-bottom">
                                    <div className="uk-grid-small uk-flex uk-flex-center" data-uk-grid>
                                        {this.state.vials.map((vial) => (
                                            <div key={vial.token_id} className={this.state.vialsGrid}>
                                                {
                                                vial.token_id > 0 ?
                                                <div>
                                                    <div className="vial">
                                                        <div className="image"></div>
                                                        <div className="name">{ this.state.currentStage === 0 && !activation ? <Button label={"Vial #" + vial.token_id} inactive={true} /> : <MigrateButton token_id={vial.token_id} refreshLabel={this.state.labels._VOLTZ_MIGRATE_RELOAD_AFTER_TX} /> }</div>
                                                    </div>
                                                </div>
                                                :
                                                <div>
                                                    <div className="vial hidden">
                                                        <div className="image"></div>
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                <div className="uk-container uk-margin-large-bottom">
                                    <div className="uk-grid-small uk-flex uk-flex-center" data-uk-grid>
                                        <div className="uk-margin-medium-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        :
                            <div>
                                <div className="uk-container uk-margin-medium-bottom uk-text-center">
                                    <div><a href={this.state.selectedAccountLinkToOpensea} target="_blank" rel="noreferrer"><div className="avatar-icon"></div></a><a href={this.state.selectedAccountLinkToOpensea} target="_blank" rel="noreferrer"><div className="avatar-icon"></div></a><a href={this.state.selectedAccountLinkToOpensea} target="_blank" rel="noreferrer"><div className="avatar-icon"></div></a></div>
                                    <div><p className="response-text">{ this.state.labels._VOLTZ_MIGRATE_NO_VIALS_AVATAR_TEXT ? parse(this.state.labels._VOLTZ_MIGRATE_NO_VIALS_AVATAR_TEXT) : null }</p></div>
                                </div>
                                <div className="uk-container uk-margin-large-bottom uk-text-center">
                                    <div className="vial-image uk-margin-bottom"><img src={"./img/voltz-vial.png"} alt="VOLTZ Vial" /></div>
                                    { this.state.labels._VOLTZ_MIGRATE_NO_VIALS_IN_THIS_WALLET ? <p className="response-text">{ parse(this.state.labels._VOLTZ_MIGRATE_NO_VIALS_IN_THIS_WALLET) }</p> : null }
                                </div>
                            </div>
                        )
                    :
                    <div className="vial-image uk-margin-large-top uk-margin-large-bottom"><img src={"./img/voltz-vial.png"} alt="VOLTZ Vial" /></div>
                    }

                </div>
            );

        }

        return (
            <div className="view">
                { this.state.spinner && ( <Spinner /> )}
                <div className="uk-container uk-margin-medium-bottom uk-text-center">
                    <div>
                        <h1>{ this.state.labels._VOLTZ_MIGRATE_TITLE }</h1>
                    </div>
                </div>

                { content }

            </div>
        );

    }

}

export default Migrate;
