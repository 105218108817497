import _ from "lodash";

function fetchDefaultHeaders(headers = {}) {
    const defaultHeaders = {
        headers: {
            "Api-Key": process.env.REACT_APP_API_KEY
        }
    }

    return _.merge(defaultHeaders, headers);
}

const api = {
    // collectionAvatar: async () => {
    //     let url = '/cache/labels.json';
    //     let cached_response = sessionStorage.getItem(url);
    //     if (cached_response) {
    //         cached_response = JSON.parse(cached_response);
    //         if ((+new Date() - cached_response.time) < (60 * 5 * 1000)) {
    //             return cached_response.response;
    //         }
    //     }

    //     let response = await fetch(url, fetchDefaultHeaders())
    //         .then(res => res.json());

    //     cached_response = JSON.stringify({
    //         'time': +new Date(),
    //         'response': response
    //     })

    //     sessionStorage.setItem(url, cached_response);
    //     return response;
    // },
    collectionAvatar: () => {
        return fetch(process.env.REACT_APP_API_BASE_URL + '/nft/collection/' + process.env.REACT_APP_NFT_COLLECTION_AVATAR_ID, fetchDefaultHeaders())
            .then(res => res.json());
    },
    checkVial: (address) => {
        return fetch(process.env.REACT_APP_API_BASE_URL + '/nft/vial/' + address, fetchDefaultHeaders())
            .then(res => res.json());
    },
    migrate: (vial_id, data) => {
        return fetch(process.env.REACT_APP_API_BASE_URL + '/nft/migrate/' + vial_id, fetchDefaultHeaders())
            .then(res => res.json());
    }
}

export default api;
