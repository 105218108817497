import React from 'react';
import Migrate from '../views/Migrate';

class Main extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            wallet: null,
            selectedChain: null,
            selectedAccount: null
        };

        if (typeof(window.ethereum) != 'undefined') {
            window.ethereum.request({ method: 'eth_accounts' }).then((accounts) => {
                this.setState({
                    wallet: window.ethereum,
                    selectedChain: window.ethereum.chainId,
                    selectedAccount: accounts[0]
                });

                this.state.wallet.on('accountsChanged', (accounts) => {
                    this.setState({ selectedAccount: accounts[0] });
                })

                this.state.wallet.on('chainChanged', (chainId) => {
                    this.setState({ selectedChain: chainId });
                })
            });
        }
    }

    render() {
        return (
            <div className="view wallet">
                <Migrate />
            </div>
        );
    }

}

export default Main;
